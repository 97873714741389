<template>
  <div>
    <div
      class="selector"
      @click="clickSelector"
    >
      <span class="placeholder">
        {{ placeholder }}
      </span>
    </div>
    <div class="project-list">
      <template v-for="(item, index) in projectList">
        <el-tag
          :key="item.companyId"
          class="tag-item"
          closable
          type="info"
          size="mini"
          @close="deleteProject(item, index)"
        >
          {{ item.companyName }}
        </el-tag>
      </template>
    </div>
  </div>
</template>
<script>

export default {
  name: '',
  props: {
    // 个人路演为PersonalRoadshow，路演活动为Roadshow
    projectType: {
      type: String,
      default: 'PersonalRoadshow',
    },
    projectList: {
      type: Array,
      default: () => [],
    },
  },
  inject: ['pageEmitter'],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    placeholder() {
      if (this.projectType === 'PersonalRoadshow') {
        return '请添加需要展示的项目';
      }
      if (this.projectType === 'Roadshow') {
        return '请搜索并添加需要展示的项目';
      }
      return '请搜索并添加需要展示的项目';
    },
  },
  methods: {
    async clickSelector() {
      if (this.projectType === 'PersonalRoadshow') {
        this.pageEmitter.emit('show-form-item', {
          value: 'PersonalRoadshow',
          title: '添加项目',
          defaultData: {
            value: this.projectList,
          },
        });
      }
      if (this.projectType === 'Roadshow') {
        this.pageEmitter.emit('show-form-item', {
          value: 'Roadshow',
          title: '添加项目',
          defaultData: {
            value: this.projectList,
          },
        });
      }
    },
    deleteProject(item, index) {
      this.projectList.splice(index, 1);
      this.$emit('update:projectList', this.projectList);
      this.$emit('list-change', this.projectList);
    },
  },
};
</script>
<style lang="scss" scoped>
.selector {
  border: 1px solid #e4e5eb;
  border-radius: 4px;
  padding: 0 7px;
  height: 28px;

  &:hover {
    border-color: #3e74ca;
  }
}

.placeholder {
  color: #959aa2;
  font-size: 14px;
  line-height: 26px;
}

.project-list {
  margin-top: 8px;
  line-height: 18px;
}

.input-tip {
  font-size: 12px;
  color: #959aa2;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: 400;
}

.tag-item {
  background: #f2f4f6;
  border-radius: 4px;
  font-size: 12px;
  color: #333b44;
  line-height: 18px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.tag-item.el-tag--info {
  border-color: #e4e5eb;
  padding: 0 9px 0 6px;

  /deep/ .el-icon-close {
    transform: scale(1);
    margin-left: -6px;

    &:hover {
      color: #fff;
      background-color: #9699ac;
      transform: scale(0.8);
    }
  }
}

</style>
